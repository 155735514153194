jQuery(document).ready(function () {
    var searches = getRecentSearchesFromLS();
    if (searches !== null && searches.length > 0) {
        var div = document.getElementById("recentSearchBlock");
        if (div !== null) {
            div.setAttribute('class', 'display-block');
            var select = document.getElementById('recentSearchDropDown');
            jQuery.each(searches, function (i) {
                select.options[select.options.length] = new Option(
                        searches[i].elementID, searches[i].elementID);
            })
            select.options[select.options.length] = new Option(
                    "Clear History", "xxx");
        }
    }
});
